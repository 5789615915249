import React from 'react';
import PropTypes from "prop-types";
import { InputLabel, MenuItem, FormControl, Select }  from '@mui/material';

export default class WordLength extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
  };

  render() {
    const options = Array(10).fill(null).map((step, value) => {
      const wordLength = value + 3;

      return (
        <MenuItem key={value} value={wordLength}>{wordLength} Letters</MenuItem>
      );
    });

    return (
      <FormControl>
        <InputLabel htmlFor="word-length-select">Word Length</InputLabel>
        <Select value={this.props.value} onChange={this.props.onChange} inputProps={{ id: "word-length-select" }}>
          {options}
        </Select>
      </FormControl>
    );
  }
}

