import React from 'react';
import ReactDOM from 'react-dom/client';
import WordleApp from './Wordle/WordleApp';
import ScrabbleApp from './Scrabble/ScrabbleApp';
import Privacy from './privacy';
import { Image } from 'mui-image';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { Copyright } from '@mui/icons-material';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';

import logoFile from './static/img/HelpWithWords.png';

function Home(props) {
  return (
    <>
      <Typography variant="body1">
        <b>Help With Words</b> is a simple to use helper for various types of word puzzles and games.  There are two types of puzzles and games that this site provides help for.  The first are <b>Wordle!</b> style puzzles and games.  The second are <b>Scrabble</b> style puzzles and games.
      </Typography>
      <hr />
      <Typography variant="body1">
        <b>Wordle!</b> style puzzles generally consist of guessing words of a fixed length.  The game will tell the player which letters are correct in the correct position, which letters are correct but not in the correct position, and which letters are not correct.  Each guess generally requires using a valid word of the appropriate length.
      </Typography>
      <br />
      <Typography variant="body1">
        In the boxes shown, after you select the size of the word to be guessed, you can enter the exact matches, valid letters, and invalid letters.  For the valid letters you can also optionally indicate the locations in the word where the letters should not be found.  This can further restrict the provided words to only those which could be solutions to the puzzle.
      </Typography>
      <hr />
      <Typography variant="body1">
        <b>Scrabble</b> style puzzles consist of a fixed list of letters from which words can be formed.  The solutions will be words of varying lengths formed from the letters provided.  Letters can be repeated as the various types of games of this kind expect the explicitly listed letters to be formed into words.
      </Typography>
      <hr />
      <Typography variant="h4">
        Disclaimer!
      </Typography>
      <Typography variant="body1">
        <b>SCRABBLE®</b> is a registered trademark. All intellectual property rights in and to the game are owned in the U.S.A and Canada by Hasbro Inc., and throughout the rest of the world by J.W. Spear & Sons Limited of Maidenhead, Berkshire, England, a subsidiary of Mattel Inc. Mattel and Spear are not affiliated with Hasbro. Words with Friends is a trademark of Zynga with Friends.
      </Typography>
      <br />
      <Typography variant="body1">
        <b>Help-with-words.com</b> is not affiliated with SCRABBLE®, Mattel Inc, Hasbro Inc, Zynga with Friends or Zynga Inc in any way. This site is for entertainment purposes only.
      </Typography>
    </>
  );
}

function NoMatch(props) {
  return (
    <>
      <Typography varient="h3">No Such Page</Typography>
      <Typography variant="body1">
        The link you've provided doesn't match any valid URL on this website.  Please check the URL and try again.
      </Typography>
    </>
  );
}

class WordHelper extends React.Component {
  constructor(props) {
    super(props);

    this.allTabs = ["/", "/wordle", "/scrabble"];

    this.state = {
      tab: window.location.pathname,
    };
  }

  handleTabChange(evt) {
    this.setState({
      tab: evt.target.pathname,
    });
  }

  MyTabs() {
    const currentTab = (this.allTabs.includes(this.state.tab)) ? this.state.tab : false;
    return (
      <>
        <Tabs value={currentTab} onChange={(evt) => this.handleTabChange(evt)}>
          <Tab label="Home" value={this.allTabs[0]} component={Link} to={this.allTabs[0]} />
          <Tab label="Wordle! Style Puzzles" value={this.allTabs[1]} component={Link} to={this.allTabs[1]} />
          <Tab label="Scrabble Style Puzzles" value={this.allTabs[2]} component={Link} to={this.allTabs[2]} />
        </Tabs>
      </>
    );
  }

  render() {
    const year = new Date().getFullYear();

    return (
      <BrowserRouter>
        <Box width="100%" typography="body1">
          <Link to="/"><Image width={300} src={logoFile} /></Link>
          {this.MyTabs()}
          <Routes>
            <Route path={this.allTabs[0]} element={<Home />} />
            <Route path={this.allTabs[1]} element={<WordleApp />} />
            <Route path={this.allTabs[2]} element={<ScrabbleApp />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="*" element={<NoMatch />} status={404} />
          </Routes>
          <hr />
          <Typography variant="body2"><Copyright /> {year} help-with-words.com - <a href="/privacy">Privacy Policy</a></Typography>
        </Box>
      </BrowserRouter>
    );
  }
}

// ========================================

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<WordHelper />);

