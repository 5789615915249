import React from 'react';
import { Box, Link, Typography } from '@mui/material';

export default class Privacy extends React.Component {
  render() {
    return (
      <>
        <Typography variant="h3">Our Privacy Policy</Typography>

        <Typography variant="body2"><em>Privacy Policy for <b>help-with-words.com</b></em></Typography>

        <br />
        <Box>
          <Typography variant="body1">The privacy of our visitors, here at <b>help-with-words.com</b> is very important to us.</Typography>

          <br />
          <Typography variant="body1">This page clearly outlines the types of personal information collected and recorded when you visit and use our website. Why do we collect certain information and how we safeguard and use that information.</Typography>
        </Box>

        <hr />

        <Box>
          <Typography variant="h4">General Data Protection Regulation (GDPR)</Typography>

          <Typography variant="body1">We are a Data Controller of your information.</Typography>

          <br />
          <Typography variant="body1"><b>help-with-words.com</b> legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Information we collect and the specific context in which we collect the information:</Typography>

          <ul>
            <li><b>help-with-words.com</b> needs to perform a contract with you</li>
            <li>You have given <b>help-with-words.com</b> permission to do so</li>
            <li>Processing your personal information is in <b>help-with-words.com</b> legitimate interests</li>
            <li><b>help-with-words.com</b> needs to comply with the law</li>
          </ul>

          <Typography variant="body1"><b>help-with-words.com</b> will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.</Typography>

          <br />
          <Typography variant="body1">If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you wish to be informed what Personal Information we hold about you and if you want it to be removed from our systems, please contact us.</Typography>

          <br />
          <Typography variant="body1">In certain circumstances, you have the following data protection rights:</Typography>

          <ul>
            <li>The right to access, update or to delete the information we have on you.</li>
            <li>The right of rectification.</li>
            <li>The right to object.</li>
            <li>The right of restriction.</li>
            <li>The right to data portability</li>
            <li>The right to withdraw consent</li>
          </ul>
        </Box>

        <hr />

        <Box>
          <Typography variant="h4">Log Files</Typography>

          <Typography variant="body1"><b>help-with-words.com</b> follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting service's analytics.</Typography>

          <br />
          <Typography variant="body1">The information collected by log files include -</Typography>

          <ul>
            <li>IP(Internet Protocol) Address</li>
            <li>ISP (Internet Service Provider)</li>
            <li>Web Browser type</li>
            <li>Your visit time</li>
            <li>date and time stamp</li>
            <li>referring/exit pages</li>
          </ul>
          <Typography variant="body1">These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking user's movement on the website, and gathering demographic information.</Typography>
        </Box>

        <hr />

        <Box>
          <Typography variant="h4">Cookies & Web Beacons</Typography>

          <Typography variant="body1">Like any other website, <b>help-with-words.com</b> uses 'cookies'. These cookies are used to store information including visitor's preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the user's experience by customizing our web page content based on visitor's browser type and/or other information.</Typography>

          <ul>
            <li>Google Analytics - For Analytics</li>
            <li>Twitter, Facebook, Google Plus, YouTube - For social interaction</li>
          </ul>

          <Typography variant="h5">Google DoubleClick DART Cookie</Typography>

          <Typography variant="body1">Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL – https://policies.google.com/technologies/ads</Typography>

          <br />
          <Typography variant="body1">You could opt out of personalized ads and control how google show you ads by visiting this page : <Link href="https://adssettings.google.com">https://adssettings.google.com</Link> and <Link href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</Link>.</Typography>

          <Typography variant="h5">Our Advertising Partners</Typography>

          <Typography variant="body1">Some of advertisers on our site may use cookies and web beacons. Some of our major advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.</Typography>

          <ul>
            <li>Google - <Link href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</Link></li>
            <li>Amazon - <Link href="https://www.amazon.com/gp/help/customer/display.html?nodeId=468496">https://www.amazon.com/gp/help/customer/display.html?nodeId=468496</Link></li>
            <li>Sovrn - <Link href="https://www.sovrn.com/privacy-policy/">https://www.sovrn.com/privacy-policy/</Link></li>
            <li>DistrictM - <Link href="https://districtm.net/en/page/platforms-data-and-privacy-policy/">https://districtm.net/en/page/platforms-data-and-privacy-policy/</Link></li>
            <li>Index Exchange - <Link href="https://www.indexexchange.com/privacy/">https://www.indexexchange.com/privacy/</Link></li>
            <li>AppNexus - <Link href="https://www.appnexus.com/platform-privacy-policy">https://www.appnexus.com/platform-privacy-policy</Link></li>
            <li>OpenX - <Link href="https://www.openx.com/legal/privacy-policy/">https://www.openx.com/legal/privacy-policy/</Link></li>
            <li>Rubicon Project - <Link href="https://rubiconproject.com/privacy-policy/">https://rubiconproject.com/privacy-policy/</Link></li>
            <li>Pubmatic - <Link href="https://pubmatic.com/legal/privacy-policy/">https://pubmatic.com/legal/privacy-policy/</Link></li>
          </ul>

          <Typography variant="body1">Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on <b>help-with-words.com</b>, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</Typography>

          <br />
          <Typography variant="body1">Note that <b>help-with-words.com</b> has no access to or control over these cookies that are used by third-party advertisers.</Typography>

          <br />
          <Typography variant="body1">You can choose to disable or turn off our cookies or third party cookies in your browser settings, However this can affect the usability of our website, and other websites as well.</Typography>
        </Box>

        <hr />

        <Box>
          <Typography variant="h4">Children's Information</Typography>

          <Typography variant="body1">Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</Typography>

          <br />
          <Typography variant="body1"><b>help-with-words.com</b> does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</Typography>
        </Box>

        <hr />

        <Box>
          <Typography variant="h4">Consent</Typography>

          <Typography variant="body1">By using our website, you hereby consent to our Privacy Policy and agree to its terms.</Typography>

          <br />
          <Typography variant="body1">You can ask questions or request for removing your personal information here : hello [at] <b>help-with-words.com</b></Typography>
        </Box>
        <br />

        <Typography variant="caption">Updated on December 27, 2022</Typography>
      </>
    );
  }
}

