import React from 'react';
import { TextField } from '@mui/material';

export default class InputWord extends React.Component {
  render() {
    return (
      <TextField inputRef={this.props.inputRef} size="small" variant="outlined" placeholder="Enter Letters" value={this.props.value} onChange={this.props.onChange} />
    );
  }
}

