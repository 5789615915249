import React from 'react';
import PropTypes from "prop-types";
import { InputLabel, MenuItem, FormControl, Select }  from '@mui/material';

export default class ExtraLetters extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
  };

  render() {
    const optionArray = {"wordlen": "By Word Length", "score": "By Score"};
    const options = Object.keys(optionArray).map((key, value) => {
      return (
        <MenuItem key={key} value={key}>{optionArray[key]}</MenuItem>
      );
    });

    return (
      <FormControl>
        <InputLabel htmlFor="sort-by-select">Sort Results By</InputLabel>
        <Select typography="body1" value={this.props.value} onChange={this.props.onChange} inputProps={{ id: "sort-by-select" }}>
          {options}
        </Select>
      </FormControl>
    );
  }
}

