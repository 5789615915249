import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { grey, yellow } from '@mui/material/colors';

import { GetAPIURL } from '../Common/query';

import ClearButton from '../Common/ClearButton';
import UpdateButton from '../Common/UpdateButton';
import InputWord from './InputWord';
import ScrabbleResults from './Results';
import ExtraLetters from './ExtraLetters';
import SortResultsBy from './SortResultsBy';

export default class ScrabbleApp extends React.Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    this.state = {
      letters: "",
      extras: 0,
      sortby: "wordlen",
      results: null,
    };
  }

  componentDidMount() {
    this.inputRef.current.focus();
  }

  handleLettersChange(evt) {
    this.setState({ letters: evt.target.value });
  }

  handleExtrasChange(evt) {
    this.setState({ extras: evt.target.value });
  }

  handleSortByChange(evt) {
    this.setState({ sortby: evt.target.value });
  }

  handleClearClick(evt) {
    this.setState({
      letters: "",
      extras: 0,
      results: null,
    });

    this.inputRef.current.focus();
  }

  handleUpdateClick(evt) {
    this.setState({
      results: "loading...",
    });

    const newLetters = this.state.letters.toLowerCase().replace(/[^a-z]+/g, "");
    this.setState({ letters: newLetters });

    this.getResults();
  }

  async getResults() {
    const URL = GetAPIURL() + "/list-words/" + this.state.letters.toLowerCase() + "?extra=" + this.state.extras;

    console.log("url = " + URL);
    const response = await fetch(URL, { });
    const data = await response.json();
    this.setState({
      results: data,
    });
  }

  render() {
    const greyStyle = {
      width: "25px",
      textAlign: "center",
      border: "1px solid #000",
      backgroundColor: grey["50"],
    };
    const yellowStyle = {
      width: "25px",
      textAlign: "center",
      border: "1px solid #000",
      backgroundColor: yellow["300"],
    };

    return (
      <>
        <br />
        <Grid container>
          <Grid item xs={5}>
            <InputWord inputRef={this.inputRef} value={this.state.letters} onChange={(evt) => this.handleLettersChange(evt)} />
          </Grid>
          <Grid item xs={2}>&nbsp;</Grid>
          <Grid item xs={5}>
            <ExtraLetters value={this.state.extras} onChange={(evt) => this.handleExtrasChange(evt)} />
          </Grid>
          <Grid item xs={7}>&nbsp;</Grid>
          <Grid item xs={5}>
            <SortResultsBy value={this.state.sortby} onChange={(evt) => this.handleSortByChange(evt)} />
          </Grid>
        </Grid>
        <hr />

        <Grid container>
          <Grid item xs={3}>
            <ClearButton value="" onClick={(evt) => this.handleClearClick(evt)} />
          </Grid>
          <br />
          <Grid item xs={6} />
          <Grid item xs={3}>
            <UpdateButton value="" onClick={(evt) => this.handleUpdateClick(evt)} />
          </Grid>
        </Grid>
        <br />
        <ScrabbleResults parentState={this.state} value={this.state.results} sortby={this.state.sortby} />
        <hr />
        <Typography variant="body1">
          <b>Scrabble</b> style puzzles consist of a fixed list of letters from which words can be formed.  The solutions will be words of varying lengths formed from the letters provided.  Letters can be repeated as the various types of games of this kind expect the explicitly listed letters to be formed into words.
        </Typography>
        <br />
        <Typography variant="h3">
          How to Use This Helper
        </Typography>
        <Typography variant="body1">
          Enter the letters you've been given into the text field provided.  It doesn't matter if the letters are upper or lower case.  Put in all the letters, including duplicates, you've been given.  The helper will then form as many words as possible from the given letters.
        </Typography>
        <br />
        <Typography variant="body1">
          The results you'll be given will show the longest available words by default from those letters first followed by shorter words.  Each word shown can be clicked to bring up the definition of the word.  On rare occasions where we don't have the definition for a more obscure word, there will be a link to allow you to do a web search for the definition.  This should normally allow you to find the definition for any word.
        </Typography>
        <br />
        <Typography variant="body1">
          You can also choose the sort the results by score.  In this case standard <b>Scrabble</b> scoring by letter is used to compute the score of the given word.  The highest scoring words will be listed first followed by words that have lower scores.  You can switch back and forth between showing the results by word length and by score quickly and easily.  In either case, the score for each word using standard <b>Scrabble</b> scoring will be shown after each word as a small number.
        </Typography>
        <br />
        <Typography variant="body1">
          In many games, any word you guess can be placed on a game board that already contains words on it.  This includes games like <b>Words with Friends</b> and other classic <b>Scrabble</b> games.  In those cases, you can have the helper show you words where one or two additional letters are added.  Simply select how many extra letters you would like to search for words with from the drop-down.  These words will have an extra letter or two added when we search for words.  In the resuling list of words, any potential extra characters will be marked in <span style={{ backgroundColor: yellow["300"] }}>yellow</span> such as the following:
        </Typography>
        <br />
        <Button variant="text">
          <Typography sx={greyStyle} noWrap>D</Typography>
          <Typography sx={greyStyle} noWrap>U</Typography>
          <Typography sx={greyStyle} noWrap>S</Typography>
          <Typography sx={greyStyle} noWrap>K</Typography>
          <Typography sx={yellowStyle} noWrap>Y</Typography>
        </Button>
        <br />
        <br />
        <Typography variant="body1">
          Sometimes the extra letter added to the word is a letter that you already entered.  In that case, each of the examples of the letter will be shown in <span style={{ backgroundColor: yellow["300"] }}>yellow</span> since any of them could be used as the extra copy of that letter.
        </Typography>
        <hr />
        <Typography variant="h4">
          Disclaimer!
        </Typography>
        <Typography variant="body1">
          <b>SCRABBLE®</b> is a registered trademark. All intellectual property rights in and to the game are owned in the U.S.A and Canada by Hasbro Inc., and throughout the rest of the world by J.W. Spear & Sons Limited of Maidenhead, Berkshire, England, a subsidiary of Mattel Inc. Mattel and Spear are not affiliated with Hasbro. Words with Friends is a trademark of Zynga with Friends.
        </Typography>
        <br />
        <Typography variant="body1">
          <b>Help-with-words.com</b> is not affiliated with SCRABBLE®, Mattel Inc, Hasbro Inc, Zynga with Friends or Zynga Inc in any way. This site is for entertainment purposes only.
        </Typography>
      </>
    );
  }
}

