import React from 'react';
import { Button } from '@mui/material';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

export default class GuessApplyButton extends React.Component {
  render() {
    return (
      <Button ref={this.props.guessApplyRef} variant="contained" endIcon={ <ArrowCircleDownIcon /> } value={this.props.value} onClick={this.props.onClick} >
        Apply!
      </Button>
    );
  }
}

