import React from 'react';
import { Typography } from '@mui/material';
import { green, grey, yellow } from '@mui/material/colors';

export default class Miss extends React.Component {
  render() {
    const state = this.props.misses[this.props.position];

    const style={
      width: "35px",
      height: "30px",
      lineHeight: "30px",
      textAlign: "center",
      color: grey["900"],
      border: "1px solid #000",
      backgroundColor: (state === "on") ? grey["400"] :
        ((state === "match") ? green["300"] :
         ((state === "partial") ? yellow["300"] : grey["50"])),
    };

    return (
      <Typography key={this.props.value} variant="body2" value={this.props.value} onClick={this.props.onChange} sx={style}>
        {this.props.letter}
      </Typography>
    );
  }
}

