import React from 'react';
import Button from '@mui/material/Button';
import Search from '@mui/icons-material/Search';

export default class UpdateButton extends React.Component {
  render() {
    return (
      <Button variant="contained" color="success" startIcon={ <Search /> } value={this.props.value} onClick={this.props.onClick}>
        Show Words!
      </Button>
    );
  }
}

