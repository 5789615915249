import React from 'react';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { yellow } from '@mui/material/colors';
import HelpIcon from '@mui/icons-material/Help';

import ClearSectionButton from './ClearSectionButton';
import Partial from './Partial';

import PartialExample from '../static/img/PartialMatchExample.png';

export default class Partials extends React.Component {
  render() {
    const partials = this.props.partials.map((step, partial) => {
      if (partial < this.props.wordLength) {
        this.props.partialInputRefs[partial] = React.createRef();

        return (
          <Partial key={partial} partial={partial} innerRef={this.props.partialInputRefs[partial]} value={this.props.partials[partial]} parentHandle={this.props.parentHandle} wordLength={this.props.wordLength} idx={partial} onChange={(evt) => this.props.parentHandle.handlePartialChange(partial, evt)} />
        );
      }
      return "";
    }, this);

    return (
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="body1" sx={{ color: yellow["800"] }}>
            Valid Letters:
            <Tooltip
              arrow
              title={
                <React.Fragment>
                  This is where letters that are not in the word can be indicated.  Valid (exact or not) letters from above will be shown here as well but won't be selectable.
                </React.Fragment>
              }
            >
              <IconButton>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <ClearSectionButton onClick={(evt) => this.props.parentHandle.handleClearPartials(evt)} />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          <Typography variant="body2" sx={{ color: yellow["800"] }}>
           Miss Positions
            <Tooltip
              arrow
              title={
                <React.Fragment>
                  Check the boxes to indicate where the near matches were found.  The system will ignore words where the given letter is in the checked position.
                  <br />
                  <br />
                  In the example below, <b>E</b> is a letter in the word, but words where <b>E</b> is the second letter will be removed from the final list of words.
                  <br />
                  <img src={PartialExample} alt="Partial Match Example" width="250" />
                  <br />
                </React.Fragment>
              }
            >
              <IconButton>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid>
        {partials}
      </Grid>
    );
  }
}

