import React from 'react';
import { Typography } from '@mui/material';

import { GetAPIURL, GenerateURI } from '../Common/query';

export default class WordCount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      uri: "",
      wordCount: "loading...",
    };
  }

  async getWordCount() {
    const uri = this.props.value.wordLength + "?" + GenerateURI(this.props.value);
    const URL = GetAPIURL() + "/word-count/" + uri;

    console.log("url = " + URL);
    const response = await fetch(URL, { });
    const data = await response.json();
    this.setState({
      uri: uri,
      wordCount: data.count.toLocaleString("en-US"),
    });
  }

  render() {
    const uri = this.props.value.wordLength + "?" + GenerateURI(this.props.value);
    if (uri !== this.state.uri) {
      this.getWordCount();
    }

    return (
      <Typography variant="body1">{this.state.wordCount}</Typography>
    );
  }
}

