import React from 'react';
import Button from '@mui/material/Button';
import Delete from '@mui/icons-material/Delete';

export default class ClearButton extends React.Component {
  render() {
    return (
      <Button variant="outlined" startIcon={ <Delete /> } value={this.props.value} onClick={this.props.onClick}>
        Clear Form!
      </Button>
    );
  }
}

