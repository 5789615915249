import React from 'react';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export default class ClearSectionButton extends React.Component {
  render() {
    return (
      <Button variant="contained" endIcon={ <DeleteIcon /> } value={this.props.value} onClick={this.props.onClick} color="error">
        Clear!
      </Button>
    );
  }
}

