import React from 'react';
import { Checkbox, Grid, TextField } from '@mui/material';
import { yellow } from '@mui/material/colors';

export default class Partial extends React.Component {
  render() {
    const style={
      width: "45px",
      textAlign: "center",
      backgroundColor: (this.props.value.chr !== "") ? yellow["300"] : "white",
    };

    const checkBoxes = this.props.value.positions.map((checkFlag, partial) => {
      if (partial < this.props.wordLength) {
        return (
          <Checkbox
            key={partial}
            size="small"
            value={partial}
            checked={checkFlag}
            onChange={this.props.onChange}
            sx={style}
          />
        );
      }
      return "";
    }, this);

    return (
      <>
        <Grid item xs={1}>
          <TextField size="small" inputRef={this.props.innerRef} value={this.props.value.chr} onFocus={(evt) => this.props.parentHandle.handlePartialFocus(this.props.partial, evt)} onChange={this.props.onChange} sx={style} />
        </Grid>
        <Grid item xs={11}>
          {checkBoxes}
        </Grid>
      </>
    );
  }
}

