import React from 'react';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import HelpIcon from '@mui/icons-material/Help';

import GuessLetter from './GuessLetter';

export default class GuessLetters extends React.Component {
  render() {
    const letters = this.props.guesses.map((state, step) => {
      if (step < this.props.wordLength) {
        const letter = this.props.guessWord.charAt(step);

        return (
          <GuessLetter key={step} state={this.props.guesses[step]} guessButtonRef={this.props.guessButtonRefs[step]} guessWord={this.props.guessWord} wordIndex={step} letter={letter} value={letter} loaded={this.props.guessLoaded} parentHandle={this.props.parentHandle} onChange={(evt) => this.props.parentHandle.handleGuessLetterChange(step, evt)} />
        );
      }
      return "";
    }, this);

    return (
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="body1" sx={{ color: green["800"] }}>
            Guess Letters:
            <Tooltip
              arrow
              title={
                <React.Fragment>
                  Enter a word guess in the box above and then click the button.  The letters from the guess word will show up in the boxes below.  You can click on each letter to change it from a miss, a correct letter in the wrong position, or a correct letter in the correct position.
                </React.Fragment>
              }
            >
              <IconButton>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={1}>&nbsp;</Grid>
        <Grid item container xs={11} wrap="nowrap" justifyContent="center" alignItems="center">
          {letters}
        </Grid>
      </Grid>
    );
  }
}
