import React from 'react';
import BarChart from 'react-bar-chart';
import { Grid, Typography } from '@mui/material';

import ResultWord from '../Common/ResultWord';

import './Results.css';

export default class Results extends React.Component {
  render() {
    if (this.props.value === "loading...") {
      return (
        <Typography variant="subtitle1">loading...</Typography>
      );
    }

    let spacer = "";
    let words = "";
    let vowelGraph = "";
    let consonentGraph = "";
    let vowels = {'A': 0, 'E': 0, 'I': 0, 'O': 0, 'U': 0};
    let consonents = {'B': 0, 'C': 0, 'D': 0, 'F': 0, 'G': 0, 'H': 0, 'J': 0, 'K': 0, 'L': 0, 'M': 0, 'N': 0, 'P': 0, 'Q': 0, 'R': 0, 'S': 0, 'T': 0, 'V': 0, 'W': 0, 'X': 0, 'Y': 0, 'Z': 0};

    if (this.props.value !== null) {
      spacer = (
        <hr />
      );
      words = this.props.value.map((word, index) => {
        const letters = Array.from(word.toUpperCase());
        for (const idx in letters) {
          const letter = letters[idx];
          if (vowels.hasOwnProperty(letter)) {
            vowels[letter]++;
          }
          if (consonents.hasOwnProperty(letter)) {
            consonents[letter]++;
          }
        }
        return (
          <ResultWord key={"result-" + index + "-" + word} word={word} parentState={this.props.parentState} />
        );
      });

      const margin = {top: 20, right: 20, bottom: 30, left: 40};
      const vowelData = Object.keys(vowels).map((letter, idx) => {
        return { text: letter, value: vowels[letter] }
      });
      vowelGraph = (
        <>
          <Typography variant="h4">Vowel Occurances</Typography>
          <BarChart ylabel="Occurences" width={400} height={200} data={vowelData} margin={margin} />
          <br />
        </>
      );

      const consonentData = Object.keys(consonents).map((letter, idx) => {
        return { text: letter, value: consonents[letter] }
      });
      consonentGraph = (
        <>
          <Typography variant="h4">Consonent Occurances</Typography>
          <BarChart ylabel="Occurences" width={450} height={200} data={consonentData} margin={margin} />
          <br />
        </>
      );
    }

    return (
      <>
        {spacer}
        {vowelGraph}
        {consonentGraph}
        <Grid container spacing={3} justifyContent="flex-start">
          {words}
        </Grid>
      </>
    );
  }
}

