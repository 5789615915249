import React from 'react';
import { Button } from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

export default class GuessLetterButton extends React.Component {
  render() {
    return (
      <Button variant="contained" endIcon={ <ArrowCircleRightIcon /> } value={this.props.value} onClick={(evt) => this.props.parentHandle.handleGuessButtonClick(evt)} >
        Load!
      </Button>
    );
  }
}

