import React from 'react';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import HelpIcon from '@mui/icons-material/Help';

import ClearSectionButton from './ClearSectionButton';
import Match from './Match';

export default class Matches extends React.Component {
  render() {
    const matches = this.props.matches.map((step, match) => {
      if (match < this.props.wordLength) {
        this.props.matchInputRefs[match] = React.createRef();

        return (
          <Match innerRef={this.props.matchInputRefs[match]} key={match} match={match} value={this.props.matches[match]} parentHandle={this.props.parentHandle} onChange={(evt) => this.props.parentHandle.handleMatchChange(match, evt)} />
        );
      }
      return "";
    }, this);

    return (
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="body1" sx={{ color: green["800"] }}>
            Exact Matches:
            <Tooltip
              arrow
              title={
                <React.Fragment>
                  Enter exact letter matches, one per square.
                </React.Fragment>
              }
            >
              <IconButton>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <ClearSectionButton onClick={(evt) => this.props.parentHandle.handleClearMatches(evt)} />
        </Grid>
        <Grid item xs={1}>&nbsp;</Grid>
        <Grid item xs={11}>
          {matches}
        </Grid>
      </Grid>
    );
  }
}

