import React from 'react';
import { Grid, Typography } from '@mui/material';
import { yellow } from '@mui/material/colors';

import ResultWord from '../Common/ResultWord';

export default class ScrabbleResults extends React.Component {
  render() {
    if (this.props.value === "loading...") {
      return (
        <Typography variant="subtitle1">loading...</Typography>
      );
    }

    let spacer = "";
    let wordSizes = "";
    let hint = "";

    if (this.props.value !== null) {
      let returnKeys = [];
      let resultsData = {};
      let sectionTitle = "";
      if (this.props.sortby === "score") {
        // Create the output result based scrabble score.
        let scoreList = {};
        for (var key in this.props.value) {
          for (var idx = 0; idx < this.props.value[key].length; idx++) {
            const word_extra = this.props.value[key][idx];
            scoreList[word_extra[2]] = true;
            if (!resultsData.hasOwnProperty(word_extra[2])) {
              resultsData[word_extra[2]] = [];
            }
            resultsData[word_extra[2]].push(word_extra);
          }
        }
        returnKeys = Object.keys(scoreList).sort(function(a, b) {
          return a - b;
        }).reverse();
        sectionTitle = " Point Words";
      } else {
        // Create the output result based on word length (the default returned by the restful API).
        returnKeys = Object.keys(this.props.value).reverse();
        resultsData = this.props.value;
        sectionTitle = " Letter Words";
      }
      spacer = (
        <hr />
      );
      wordSizes = returnKeys.map((key, index) => {
        const words = resultsData[key].map((word_extra, idx) => {
          const word = word_extra[0];
          const extra = word_extra[1];
          const score = word_extra[2];
          return (
            <Grid key={"grid-" + index + "-" + word} item>
              <ResultWord key={"result-" + index + "-" + word} nocolors word={word} extra={extra} score={score} parentState={this.props.parentState} />
            </Grid>
          );
        });

        return (
          <Grid container key={"result-" + key + "-words"} spacing={3} justifyContent="flex-start">
            <Grid key={"words-" + key} item xs={12}>
              <Typography variant="h2">{key} {sectionTitle}</Typography>
            </Grid>
            {words}
          </Grid>
        );
      });

      if (this.props.parentState.extras > 0) {
        hint = (
          <>
            <br />
            <hr />
            <Typography variant="h4">Note:</Typography>
            <Typography variant="body2">
              Letters that are in <span style={{ backgroundColor: yellow["300"] }}>yellow</span> are potentially extra letters that the rest of the word could be attached to.  They may not be in the original set of letters from which to draw words.  This allows you to attach words to existing words in a Scrabble type game.
            </Typography>
            <Typography variant="body2">
              If you are playing a game that doesn't allow you to attach new words to existing words in this fashion, simple select <b>No Extra Letters</b> from the drop-down.
            </Typography>
          </>
        );
      }
    }

    return (
      <>
        {spacer}
        {wordSizes}
        {hint}
      </>
    );
  }
}

