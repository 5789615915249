import React from 'react';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import HelpIcon from '@mui/icons-material/Help';

import ClearSectionButton from './ClearSectionButton';
import { LetterPosition } from './util';

import Miss from './Miss';

export default class Misses extends React.Component {
  render() {
    const missRows = [
                      ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
                      ["A", "S", "D", "F", "G", "H", "J", "K", "L"],
                      ["Z", "X", "C", "V", "B", "N", "M"],
                     ];

    const misses = missRows.map((missRow, missIdx) => {
      const row = missRow.map((missLetter, miss) => {
        const position = LetterPosition(missLetter);

        return (
          <Miss key={missLetter} letter={missLetter} position={position} misses={this.props.misses} onChange={(evt) => this.props.parentHandle.handleMissChange(missLetter, evt)} />
        );
      }, this);
      return (
        <Grid key={"misses-row-" + missIdx} item container xs={12} wrap="nowrap" justifyContent="center" alignItems="center">
          {row}
        </Grid>
      );
    }, this);

    return (
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="body1" sx={{ color: grey["900"] }}>
            Invalid:
            <Tooltip
              arrow
              title={
                <React.Fragment>
                  This is where letters that are not in the word can be indicated.  Valid (exact or not) letters from above will be shown here as well but won't be selectable.
                </React.Fragment>
              }
              wrap="nowrap"
            >
              <IconButton>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <ClearSectionButton onClick={(evt) => this.props.parentHandle.handleClearInvalid(evt)} />
        </Grid>
        {misses}
      </Grid>
    );
  }
}

