import React from 'react';
import { Grid, TextField } from '@mui/material';

import GuessApplyButton from './GuessApplyButton';
import GuessLetters from './GuessLetters';
import GuessLetterButton from './GuessLetterButton';

export default class Guess extends React.Component {
  render() {
    return (
      <>
        <Grid container>
          <Grid item xs={8}>
            <TextField inputRef={this.props.inputRef} size="small" variant="outlined" placeholder="Enter Guess" value={this.props.guessWord} onChange={(evt) => this.props.parentHandle.handleGuessWordChange(evt)} />
          </Grid>
          <Grid item xs={4}>
            <GuessLetterButton parentHandle={this.props.parentHandle} onClick={this.props.onButtonClick} />
          </Grid>
        </Grid>
        <GuessLetters parentHandle={this.props.parentHandle} guessWord={this.props.guessWord} guessLoaded={this.props.guessLoaded} guessButtonRefs={this.props.guessButtonRefs} wordLength={this.props.wordLength} value={this.props.guessWord} guesses={this.props.guesses} onClick={this.props.onGuessLetterClick} />
        <Grid container>
          <Grid item xs={8}>&nbsp;</Grid>
          <Grid item xs={4}>
            <GuessApplyButton guessApplyRef={this.props.guessApplyRef} onClick={(evt) => this.props.parentHandle.handleGuessApplyClick(evt)} />
          </Grid>
        </Grid>
      </>
    );
  }
}

