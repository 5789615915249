import React from 'react';
import { TextField } from '@mui/material';
import { green } from '@mui/material/colors';

export default class Match extends React.Component {
  render() {
    const style={
      width: "45px",
      textAlign: "center",
      backgroundColor: (this.props.value !== "") ? green["300"] : "white",
    };

    return (
      <TextField size="small" inputRef={this.props.innerRef} value={this.props.value} onFocus={(evt) => this.props.parentHandle.handleMatchFocus(this.props.match, evt)} onChange={this.props.onChange} sx={style} />
    );
  }
}

