import React from 'react';
import { Typography } from '@mui/material';
import { green, grey, red, yellow } from '@mui/material/colors';

export default class GuessLetter extends React.Component {
  render() {
    var colorMap = {
      match: green["300"],
      partial: yellow["300"],
      miss: grey["50"],
      error: red["300"],
    };
    const style={
      width: "35px",
      height: "30px",
      lineHeight: "30px",
      textAlign: "center",
      color: grey["900"],
      border: "1px solid #000",
      backgroundColor: (this.props.loaded) ? colorMap[this.props.state] : grey["400"],
    };

    const showLetter = (this.props.letter !== "") ? this.props.letter : "-";

    return (
      <Typography size="small" ref={this.props.guessButtonRef} value={this.props.letter} onClick={(evt) => this.props.parentHandle.handleGuessLetterClick(this.props.letter, this.props.wordIndex, evt)} sx={style}>{showLetter}</Typography>
    );
  }
}

