import React from 'react';
import { Box, Button, Container, Grid, IconButton, Link, List, ListItemText, ListSubheader, Modal, Typography } from '@mui/material';
import { green, grey, yellow } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';

export default class ResultWord extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDefinitionModal: false,
      hasDefinition: false,
      definitionData: null,
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  async getDefinition(word) {
    const URL = "https://api.dictionaryapi.dev/api/v2/entries/en/" + word;

    if (!this.state.hasDefinition) {
      console.log("url = " + URL);
      const response = await fetch(URL, { })
        .catch(error => {
          console.log("Fetch error " + error);
        });
      if (!response.ok) {
        const data = await response.json();

        const definitions = (
          <Container key={"container-" + word}>
            <Typography key={"word-" + word} variant="h6">{word}</Typography>
            <Typography key={"title-" + word} variant="body1">{data.title}</Typography>
            <Typography key={"message-" + word} variant="body2">{data.message}</Typography>
            <Typography key={"resolution-" + word} variant="body2">{data.resolution}</Typography>
            <Typography key={"link-" + word} variant="caption"><Link key={"a-" + word} href={"https://www.google.com/search?q=" + word + "+definition"} target="_blank" rel="noopener noreferrer">Web Search</Link></Typography>
          </Container>
        );

        this.setState({
          hasDefinition: true,
          definitionData: definitions,
        });
      } else {
        const data = await response.json();
        const definitionStyle = {
          paddingLeft: "1em",
          textIndent: "-1em",
        };

        if (data !== null && data.title !== "No Definitions Found") {
          const definitions = data.map((def, index) => {
            const meanings = def.meanings.map((mean, idx) => {
              const definitionList = mean.definitions.map((dfn, i) => {
                return (
                  <ListItemText key={"word-def-" + def.word + "-" + idx + "-" + i} sx={definitionStyle}><Typography variant="body2">{(i + 1) + ".  " + dfn.definition}</Typography></ListItemText>
                );
              });

              return (
                <>
                  <ListSubheader key={"subheader-" + mean.partOfSpeech}>{mean.partOfSpeech}</ListSubheader>
                  {definitionList}
                </>
              );
            });

            return (
              <Container key={"container-" + def.word + "-" + index}>
                <Typography key={"word-" + def.word + "-" + index} variant="h6">{def.word}</Typography>
                <Typography key={"phonetic-" + def.word + "-" + index} variant="subtitle1">{def.phonetic}</Typography>
                <List key={"deflist-" + def.word + "-" + index}>
                  {meanings}
                </List>
              </Container>
            );
          });

          this.setState({
            hasDefinition: true,
            definitionData: definitions,
          });
        } else {
          const definitions = (
            <Container key={"container-" + word}>
              <Typography key={"word-" + word} variant="h6">{word}</Typography>
              <Typography key={"notfound-" + word} variant="body1">No definitions found for this word.</Typography>
            </Container>
          );

          this.setState({
            hasDefinition: true,
            definitionData: definitions,
          });
        }
      }
    }
  }

  handleOpenModal() {
    if (this.state.definitionData === null) {
      this.getDefinition(this.props.word.toLowerCase());
    }

    this.setState({
      showDefinitionModal: true,
    });
  }

  handleCloseModal() {
    this.setState({
      showDefinitionModal: false,
    });
  }

  render() {
    let result = [];

    for (let i = 0; i < this.props.word.length; i++) {
      const letter = this.props.word[i].toUpperCase();
      let color = grey["50"];
      if (!this.props.hasOwnProperty("nocolors")) {
        for (let j = 0; j < this.props.word.length; j++) {
          if (this.props.parentState.partials[j].chr === letter) {
            if (!this.props.parentState.partials[j].positions[i]) {
              color = yellow["300"];
            }
          }
        }
        if (this.props.parentState.matches[i] === letter) {
          color = green["300"];
        }
      } else {
        // Check to see if there are any extra characters to use for the color.
        const extras = this.props.extra.split("");

        if (extras.includes(this.props.word[i])) {
          color = yellow["300"];
        }
      }

      const style = {
        width: "25px",
        textAlign: "center",
        border: "1px solid #000",
        backgroundColor: color,
      };

      result.push(
        <Typography key={"letter-" + letter + "i" + i} sx={style} noWrap>{letter}</Typography>
      );
    }

    const modalStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    const closeButtonStyle = {
      float: 'right',
    };

    let score = "";
    if (this.props.hasOwnProperty("score")) {
      score = (
        <Typography variant="caption">{this.props.score}</Typography>
      );
    }

    return (
      <Grid key={"grid-" + this.props.word} item xs="auto">
        <Button key={"button-" + this.props.word} variant="text" onClick={this.handleOpenModal}>
          {result}{score}
        </Button>
        <Modal key={"modal-" + this.props.word} open={this.state.showDefinitionModal}>
          <Box key={"modal-box-" + this.props.word} sx={modalStyle}>
            <IconButton key={"modal-iconbutton-" + this.props.word} onClick={this.handleCloseModal} sx={closeButtonStyle}><CloseIcon key={"closeicon-" + this.props.word} /></IconButton>
            {this.state.definitionData}
          </Box>
        </Modal>
      </Grid>
    );
  }
}

