export function GenerateURI(state) {
  let URI = [];

  for (let i = 0; i < state.wordLength; i++) {
    if (state.matches[i] !== "") {
      URI.push("match=" + state.matches[i].toLowerCase() + i);
    }
    if (state.partials[i].chr !== "") {
      let found = false;
      for (let j = 0; j < state.wordLength; j++) {
        if (state.partials[i].positions[j]) {
          found = true;
          URI.push("partial=" + state.partials[i].chr.toLowerCase() + j);
        }
      }
      if (!found) {
        URI.push("partial=" + state.partials[i].chr.toLowerCase() + "*");
      }
    }
  }

  for (let i = 0; i < 26; i++) {
    if (state.misses[i] === "on") {
      URI.push("miss=" + String.fromCharCode(i + "a".charCodeAt(0)));
    }
  }

  return URI.join("&");
}

export function GetBaseURL() {
  return window.location.origin;
}

export function GetAPIURL() {
  const baseURL = GetBaseURL();

  if (baseURL === "http://192.168.0.5:3000") {
    return "http://192.168.0.5:5000";
  }
  if (baseURL === "http://test.help-with-words.com:3000") {
    return "http://test.help-with-words.com:5000";
  }
  if (baseURL === "http://heegaard.neilfamily.org:3000") {
    return "http://heegaard.neilfamily.org:5000";
  }

  if (baseURL === "http://test.help-with-words.com") {
    return "http://test.help-with-words.com/api";
  }
  if (baseURL === "https://test.help-with-words.com") {
    return "https://test.help-with-words.com/api";
  }

  if (baseURL === "http://www.help-with-words.com") {
    return "http://api.help-with-words.com";
  }

  return "https://api.help-with-words.com";
}

export function GetCanonicalURL() {
  const baseURL = GetBaseURL();

  if (baseURL === "http://test.help-with-words.com") {
    return "https://test.help-with-words.com";
  }

  if (baseURL === "http://help-with-words.com" || baseURL === "https://help-with-words.com" || baseURL === "http://www.help-with-words.com") {
    return "https://www.help-with-words.com";
  }

  return baseURL;
}

